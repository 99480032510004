import { OptionItem } from '@/common/types';
import { Box, Checkbox, HStack, Highlight, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MdExpandMore as DownIcon } from 'react-icons/md';

type Props = {
  searchText: string;
  currentOptions: OptionItem[];
  selectedOption?: OptionItem | undefined;
  onCheck: ({
    targetOption,
    isCheckChildren,
  }: {
    targetOption: OptionItem;
    isCheckChildren?: boolean;
  }) => void;
};

const MultipleLayerCheckboxSearchOption: FC<Props> = (props: Props) => {
  const { onCheck, selectedOption, currentOptions, searchText } = props;

  const [options, setOptions] = useState<OptionItem[]>([]);

  useEffect(() => {
    setOptions(currentOptions);
  }, [currentOptions]);

  const handleSelect = (targetOption: OptionItem) => {
    onCheck({ targetOption });
  };

  return (
    <>
      {options.map((option) => (
        <Box key={option.id} w='100%' p={2}>
          <>
            <HStack>
              <Checkbox
                w='100%'
                h='100%'
                fontWeight='500'
                colorScheme={option.isChecked ? 'primary' : ''}
                color={option.isChecked ? 'primary.600' : ''}
                bg={option.isChecked ? 'primary.100' : ''}
                isChecked={option.isChecked}
                onChange={() => handleSelect(option)}
              >
                <Text>
                  <Highlight query={searchText} styles={{ color: 'primary.600' }}>
                    {option.label}
                  </Highlight>
                </Text>
              </Checkbox>
              {option.children && option.children.length > 0 ? (
                <DownIcon color='neutral.500' />
              ) : (
                <></>
              )}
            </HStack>
            {option.matchedChild && (
              <Stack ml={5}>
                <MultipleLayerCheckboxSearchOption
                  onCheck={onCheck}
                  searchText={searchText}
                  selectedOption={selectedOption}
                  currentOptions={option.matchedChild}
                />
              </Stack>
            )}
          </>
        </Box>
      ))}
    </>
  );
};

export default MultipleLayerCheckboxSearchOption;
