import {
  Box,
  Collapse,
  Divider,
  Flex,
  FocusLock,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { MdClose as CloseIcon, MdSearch as SearchIcon } from 'react-icons/md';

import { OptionItem, OptionValue } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { createParentOptionsHashMap } from '@/utils/molecules/multiLayer';
import { IconType } from 'react-icons';
import ClosableModal from '../ClosableModal';
import MultipleLayerCheckboxOption from './MultipleLayerCheckboxOption';
import MultipleLayerCheckboxSearchOption from './MultipleLayerCheckboxSearchOption';

type MultipleLayerCheckboxProps = {
  placeholder?: string;
  label?: string;
  icon?: ReactElement<IconType>;
  options: OptionItem[];
  values?: OptionValue[];
  isDefaultOpen?: boolean;
  hasOverlay?: boolean;
  isInMenu?: boolean;
  onCloseFilter?: () => void;
  onChange: (value?: OptionValue[]) => void;
  customSettingComponent?: ReactElement;
  isClearable?: boolean;
};

export type HashArgs = {
  [id: string | number]: OptionItem;
};

const MultipleLayerCheckbox: FC<MultipleLayerCheckboxProps> = (
  props: MultipleLayerCheckboxProps
) => {
  const { t } = useTranslation();

  const {
    values,
    onChange,
    hasOverlay,
    options: rootOptions,
    isDefaultOpen = false,
    isInMenu = false,
    onCloseFilter,
    label,
    placeholder = t('actions.search'),
    customSettingComponent,
    isClearable = true,
  } = props;
  const [isShowMultiCheckboxFilter, setIsShowMultiCheckboxFilter] =
    useState<boolean>(isDefaultOpen);
  const { isMobile, isDesktop } = useScreenInfos();
  const [searchText, setSearchText] = useState<string>('');
  const [matchedOptions, setMatchedOptions] = useState<OptionItem[]>([]);
  const [checkedOptions, setCheckedOptions] = useState<OptionItem[]>([]);
  const [flattenCheckedOptions, setFlattenCheckedOptions] = useState<OptionItem[]>([]);
  const [parentOptionsMap, setParentOptionsMap] = useState<HashArgs>({});
  const initOptions = useCallback(
    (options: OptionItem[]): OptionItem[] => {
      const checkboxOptions = options.map((option) => {
        const checkboxOption = option;
        if (option.children && option.children.length > 0) {
          checkboxOption.children = initOptions(option.children);
        }

        const isChecked = values ? values.includes(option.id) : false;

        return {
          ...checkboxOption,
          isChecked,
        };
      });

      return checkboxOptions;
    },
    [values]
  );

  const flatOptions = useCallback((options: OptionItem[]) => {
    let flattenOptions: OptionItem[] = [];
    options.forEach((option) => {
      flattenOptions = [...flattenOptions, option];
      if (option.children && option.children.length > 0) {
        flattenOptions = [...flattenOptions, ...flatOptions(option.children)];
      }
    });
    return flattenOptions;
  }, []);

  const filterOptionsByText = useCallback((target: string, options: OptionItem[]): OptionItem[] => {
    if (!target) {
      return options;
    }

    const resultOptions: OptionItem[] = options.filter((option) => {
      if (option.children && option.children.length > 0) {
        const childMatch = filterOptionsByText(target, option.children);
        option.matchedChild = []; // remove previous search results
        if (childMatch.length > 0) {
          option.matchedChild = childMatch;
          return true;
        }
      }

      return option.label.toUpperCase().includes(target.toUpperCase());
    });
    return resultOptions;
  }, []);

  const deepCheckChildren = (options: OptionItem[], isChecked?: boolean) => {
    const checkedChildrenOption = options.map((option) => {
      const checkedOption = {
        ...option,
        isChecked: !!isChecked,
      };

      if (checkedOption.children && checkedOption.children.length > 0) {
        checkedOption.children = deepCheckChildren(checkedOption.children, isChecked);
      }
      return checkedOption;
    });

    return checkedChildrenOption;
  };

  const checkOptions = ({
    targetOption,
    options,
    isCheckChildren = false,
  }: {
    targetOption: OptionItem;
    options: OptionItem[];
    isCheckChildren?: boolean;
  }) => {
    const checkboxOptions = options.map((option) => {
      let checkboxOption = option;
      if (checkboxOption.id === targetOption.id) {
        checkboxOption = targetOption;
        if (isCheckChildren && targetOption.children && targetOption.children.length > 0) {
          checkboxOption.children = deepCheckChildren(
            targetOption.children,
            targetOption.isChecked
          );
        }
        return checkboxOption;
      }

      if (option.children && option.children.length > 0) {
        checkboxOption.children = checkOptions({
          targetOption,
          options: option.children,
          isCheckChildren,
        });
      }

      return checkboxOption;
    });
    return checkboxOptions;
  };

  const handleRemoveAll = () => {
    resetOptions();
    onChange(undefined);
  };

  const handleRemove = (targetOption: OptionItem) => {
    handleCheck({ targetOption });
  };

  const handleCheck = ({
    targetOption,
    isCheckChildren = false,
  }: {
    targetOption: OptionItem;
    isCheckChildren?: boolean;
  }) => {
    const updatedOptions = checkOptions({
      targetOption: {
        ...targetOption,
        isChecked: !targetOption.isChecked,
      },
      options: checkedOptions,
      isCheckChildren,
    });
    setCheckedOptions(updatedOptions);
    setParentOptionsMap(createParentOptionsHashMap(updatedOptions));
    const flattenCheckedOptions = flatOptions(updatedOptions).filter((option) => option.isChecked);
    const flattenCheckedOptionsId = flattenCheckedOptions.map((option) => option.id);
    setFlattenCheckedOptions(flattenCheckedOptions);
    onChange(flattenCheckedOptionsId);

    if (searchText) {
      const filteredOptions = filterOptionsByText(searchText, updatedOptions);
      setMatchedOptions(filteredOptions);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    setIsShowMultiCheckboxFilter(true);
    const filteredOptions = filterOptionsByText(value, checkedOptions);
    setMatchedOptions(filteredOptions);
  };

  const handleShowFilter = () => {
    setSearchText('');
    setIsShowMultiCheckboxFilter(!isShowMultiCheckboxFilter);
  };

  const handleCloseModal = () => {
    setIsShowMultiCheckboxFilter(false);
    if (onCloseFilter) onCloseFilter();
  };

  const resetOptions = useCallback(() => {
    const initializedOptions = initOptions(rootOptions);
    setCheckedOptions(initializedOptions);
    setParentOptionsMap(createParentOptionsHashMap(initializedOptions));
    setFlattenCheckedOptions(flatOptions(initializedOptions).filter((option) => option.isChecked));
  }, [flatOptions, initOptions, rootOptions]);

  useEffect(() => {
    resetOptions();
  }, [resetOptions]);

  return (
    <Box pos='relative'>
      {hasOverlay && (
        <Box
          zIndex={isShowMultiCheckboxFilter ? 99 : -99}
          pos='fixed'
          inset={0}
          onClick={() => {
            setIsShowMultiCheckboxFilter(!isShowMultiCheckboxFilter);
          }}
          p={0}
          m={0}
        />
      )}
      <HStack bg='neutral.0' rounded='md' pos='relative'>
        <MultipleLayerCheckboxInputGroup
          searchText={searchText}
          placeholder={placeholder}
          flattenCheckedOptions={flattenCheckedOptions}
          isShowMultiCheckboxFilter={isShowMultiCheckboxFilter}
          handleIsShowMultiCheckboxFilter={(value: boolean) => setIsShowMultiCheckboxFilter(value)}
          handleSearch={handleSearch}
          handleRemove={handleRemove}
          handleRemoveAll={handleRemoveAll}
          handleShowFilter={handleShowFilter}
          isInMenu={isInMenu}
          isClearable={isClearable}
        />
      </HStack>

      {isDesktop && (
        <Box pos='relative' zIndex={100}>
          <Collapse in={isShowMultiCheckboxFilter} animateOpacity>
            <Box
              p={2}
              bg='neutral.0'
              rounded='md'
              border='1px'
              borderColor='neutral.200'
              pos='absolute'
              top={2}
              insetX={0}
            >
              {customSettingComponent && customSettingComponent}

              <MultipleLayerCheckboxOptionWrapper
                searchText={searchText}
                matchedOptions={matchedOptions}
                checkedOptions={checkedOptions}
                parentOptionsMap={parentOptionsMap}
                handleCheck={handleCheck}
              />
            </Box>
          </Collapse>
        </Box>
      )}
      {isMobile && (
        <ClosableModal
          isOpen={isShowMultiCheckboxFilter}
          onClose={() => handleCloseModal()}
          size={{ base: 'full', md: 'lg' }}
          scrollBehavior='inside'
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p={3} borderTop='1px' borderColor='neutral.300' bg='neutral.0'>
              <Flex>
                <Text mx='auto' fontWeight={700} my={3}>
                  {label}
                </Text>
              </Flex>
              {customSettingComponent && customSettingComponent}
              <MultipleLayerCheckboxInputGroup
                searchText={searchText}
                placeholder={placeholder}
                flattenCheckedOptions={flattenCheckedOptions}
                isShowMultiCheckboxFilter={isShowMultiCheckboxFilter}
                handleIsShowMultiCheckboxFilter={(value: boolean) =>
                  setIsShowMultiCheckboxFilter(value)
                }
                handleSearch={handleSearch}
                handleRemove={handleRemove}
                handleRemoveAll={handleRemoveAll}
                handleShowFilter={handleShowFilter}
                isModal
                isInMenu={isInMenu}
                isClearable={isClearable}
              />
              <Divider mt={2} />
              <Box
                bg='neutral.0'
                mt={1}
                py={2}
                rounded='md'
                border='1px'
                borderColor={{
                  base: 'transparent',
                  md: 'neutral.200',
                }}
              >
                <MultipleLayerCheckboxOptionWrapper
                  searchText={searchText}
                  matchedOptions={matchedOptions}
                  checkedOptions={checkedOptions}
                  parentOptionsMap={parentOptionsMap}
                  handleCheck={handleCheck}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </ClosableModal>
      )}
    </Box>
  );
};

type MultipleLayerCheckboxInputGroupProps = {
  searchText: string;
  placeholder?: string;
  flattenCheckedOptions: OptionItem[];
  isModal?: boolean;
  isShowMultiCheckboxFilter: boolean;
  isInMenu: boolean;
  handleIsShowMultiCheckboxFilter: (value: boolean) => void;
  handleSearch: (value: string) => void;
  handleRemove: (targetOption: OptionItem) => void;
  handleRemoveAll: () => void;
  handleShowFilter: () => void;
  isClearable: boolean;
};

const MultipleLayerCheckboxInputGroup = (props: MultipleLayerCheckboxInputGroupProps) => {
  const {
    searchText,
    placeholder,
    flattenCheckedOptions,
    isInMenu,
    handleIsShowMultiCheckboxFilter,
    handleSearch,
    handleRemove,
    handleRemoveAll,
    isClearable,
  } = props;

  const { isDesktop } = useScreenInfos();

  const inputField = (
    <Input
      h='34px'
      pl={8}
      value={searchText}
      onChange={(event) => handleSearch(event.target.value)}
      onClick={() => handleIsShowMultiCheckboxFilter(true)}
      placeholder={placeholder}
      _placeholder={{ color: 'neutral.500' }}
    />
  );

  const onRemoveClick = (e: React.MouseEvent, option: OptionItem) => {
    e.preventDefault();
    e.stopPropagation();
    handleRemove(option);
  };
  return (
    <>
      <InputGroup size='md'>
        <InputLeftElement pointerEvents='none' h='100%'>
          <SearchIcon color='neutral.500' />
        </InputLeftElement>
        {flattenCheckedOptions.length > 0 ? (
          <Box
            pl={8}
            py={1}
            pr='5rem'
            w='100%'
            minH='2.5rem'
            maxH='5.5rem'
            rounded='md'
            overflowY='auto'
            sx={{
              border: '1px solid #ebebeb',
            }}
            onClick={() => handleIsShowMultiCheckboxFilter(true)}
          >
            <Box minW='100%' pos='relative' zIndex={100}>
              {flattenCheckedOptions.map((option, index) => (
                <Tag key={index} m={1}>
                  <TagLabel>{option.label}</TagLabel>
                  <TagCloseButton onClick={(e) => onRemoveClick(e, option)} />
                </Tag>
              ))}
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            {/* menuの中にこのコンポーネントが存在する場合、focusが当たらないので、FocusLockを使用している */}
            {isInMenu && isDesktop ? <FocusLock>{inputField}</FocusLock> : inputField}
          </Box>
        )}

        {flattenCheckedOptions.length > 0 && isClearable && (
          <InputRightElement w='5rem' h='100%' justifyContent='end'>
            <HStack spacing={0} py={0} h='100%'>
              <IconButton
                as='div'
                fontSize={16}
                variant='unstyled'
                display='flex'
                justifyContent='center'
                alignItems='center'
                aria-label='Clear'
                color='neutral.500'
                h='40px'
                w='40px'
                icon={<CloseIcon />}
                onClick={() => handleRemoveAll()}
              />
            </HStack>
          </InputRightElement>
        )}
      </InputGroup>
    </>
  );
};

type MultipleLayerCheckboxOptionWrapperProps = {
  searchText: string;
  matchedOptions: OptionItem[];
  checkedOptions: OptionItem[];
  parentOptionsMap: HashArgs;
  handleCheck: ({
    targetOption,
    isCheckChildren,
  }: {
    targetOption: OptionItem;
    isCheckChildren?: boolean;
  }) => void;
};

const MultipleLayerCheckboxOptionWrapper = (props: MultipleLayerCheckboxOptionWrapperProps) => {
  const { searchText, matchedOptions, handleCheck, checkedOptions, parentOptionsMap } = props;
  const { t_errors } = useTranslation();

  return (
    <>
      {searchText ? (
        <Stack w='100%' spacing='0px' maxH={{ base: '', md: '350px' }} overflowY={'auto'}>
          {matchedOptions.length > 0 ? (
            <MultipleLayerCheckboxSearchOption
              searchText={searchText}
              onCheck={handleCheck}
              currentOptions={matchedOptions}
            />
          ) : (
            <Text>{t_errors('not-found')}</Text>
          )}
        </Stack>
      ) : (
        <Stack w='100%' spacing='0px' maxH={{ base: '', md: '350px' }} overflowY={'auto'}>
          <MultipleLayerCheckboxOption
            onCheck={handleCheck}
            rootOptions={checkedOptions}
            parentOptionsMap={parentOptionsMap}
          />
        </Stack>
      )}
    </>
  );
};

export { MultipleLayerCheckboxOptionWrapper };

export default MultipleLayerCheckbox;
